import React from "react"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <div style={{height: '100vh', width: '100vw', lineHeight: '100vh', textAlign: 'center'}}>NOT FOUND</div>
  </>
)

export default NotFoundPage
